import type {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';

import { createAppManifest } from './components/Profile/manifest';
import { registerGfppEventListeners } from './editor/services/gfpp-event-listener';
import * as publicApi from './editor/services/public-api';

let flowAPI: EditorScriptFlowAPI;

const editorReady: EditorReadyFn = async (editorSDK, _, __, _flowAPI) => {
  flowAPI = _flowAPI;
  registerGfppEventListeners(flowAPI, editorSDK);
};

const getAppManifest: GetAppManifestFn = (_, editorSDK) => {
  return createAppManifest(editorSDK, flowAPI.translations.t);
};

const _exports = (editorSDK: FlowEditorSDK) => ({
  public: { refreshApp: () => publicApi.refreshApp(flowAPI, editorSDK) },
});

export { editorReady, _exports as exports, getAppManifest };
