import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';

import {
  customizeFieldsEventId,
  manageMemberPagesEventId,
} from '../../components/Profile/manifest';
import { memberAccountBMPath } from '../constants';
import { openGeneralSettingsPanel } from './members-area-api';
import { refreshApp } from './public-api';

export function registerGfppEventListeners(
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
) {
  editorSDK.addEventListener('widgetGfppClicked', ({ detail }) => {
    switch (detail.id) {
      case customizeFieldsEventId: {
        editorSDK?.editor
          .openDashboardPanel('', {
            url: `${memberAccountBMPath}?referralInfo=profile_page_gfpp_cta`,
            closeOtherPanels: false,
          })
          .then(() => refreshApp(flowAPI, editorSDK));
        break;
      }
      case manageMemberPagesEventId: {
        openGeneralSettingsPanel(
          editorSDK,
          'editor_profile_manage_member_pages',
        );
      }
    }
  });
}
