import { MEMBERS_AREA } from '@wix/app-definition-ids';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

type ReferralInfo =
  | 'editor_profile_settings'
  | 'editor_profile_manage_member_pages';

export enum MembersAreaContext {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

interface MembersAreaPublicApi {
  openGeneralSettingsPanel: (referralInfo: ReferralInfo) => Promise<void>;
  getIsMembersAreaSeoEnabled: () => Promise<boolean>;
  getIsMembersAreaV2Context: () => Promise<boolean>;
  getMembersAreaContext?: () => Promise<MembersAreaContext>;
}

const getMembersAreaApi = (editorSDK: FlowEditorSDK) => {
  return editorSDK.document.application.getPublicAPI('', {
    appDefinitionId: MEMBERS_AREA,
  }) as Promise<MembersAreaPublicApi | undefined>;
};

export const openGeneralSettingsPanel = async (
  editorSDK: FlowEditorSDK,
  referralInfo: ReferralInfo,
) => {
  const membersAreaApi = await getMembersAreaApi(editorSDK);
  return membersAreaApi?.openGeneralSettingsPanel(referralInfo);
};

export const getMembersAreaContext = async (editorSDK: FlowEditorSDK) => {
  try {
    const membersAreaApi = await getMembersAreaApi(editorSDK);

    if (membersAreaApi?.getMembersAreaContext) {
      return membersAreaApi.getMembersAreaContext();
    }

    const isMembersAreaV2 = await membersAreaApi?.getIsMembersAreaV2Context();

    return isMembersAreaV2 ? MembersAreaContext.V2 : MembersAreaContext.V1;
  } catch (e) {
    return MembersAreaContext.V1;
  }
};

export const getIsMembersAreaSeoEnabled = async (editorSDK: FlowEditorSDK) => {
  try {
    const membersAreaApi = await getMembersAreaApi(editorSDK);
    const isSeoChangesEnabled =
      await membersAreaApi?.getIsMembersAreaSeoEnabled();
    return !!isSeoChangesEnabled;
  } catch (e) {
    return false;
  }
};
