export interface BlogPosts {
  paging: { count: number; offset: number; total: number };
  createBlogPostUrl: { url: string; relativeUrl: string };
  arePostsLoading: boolean;
}

export enum PostImageFill {
  Crop = 'cover',
  Fill = 'fill',
}

export enum PostImageRatio {
  SixteenToNine = +(16 / 9).toFixed(4),
  FourToThree = +(4 / 3).toFixed(4),
  OneToOne = 1,
  ThreeToFour = +(3 / 4).toFixed(4),
  NineToSixteen = +(9 / 16).toFixed(4),
}
